import React from 'react';
import './Footer.css';
import { Button } from '../Buttons/Buttons.js';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import axios from 'axios';

function Footer() {
  async function handleSubscribe(e){
    e.preventDefault();
    const email = e.target[0].value;
    const res = await axios.get(`https://asia-south1-dose-visionai.cloudfunctions.net/sendEmailMsg91?email=${email}`);
    console.log(res.data);
    console.log(res.data.Status);
    if(res.data.Status==='SUCCESS'){
      alert('Subscribed Successfully');
    }
    else{
      alert('Subscription Failed');
    }
  }
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join our exclusive membership to receive the latest news and trends
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form onSubmit={handleSubscribe}>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
              required
            />
            <Button type='Submit'>Subscribe</Button>
          </form>
        </div>
      </section>
      
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            <img src="/favicon.ico" alt='OneDose-img' style={{width:'8rem',aspectRatio:'3.4/2',borderRadius:'.5rem'}}/>
              {/* OneDose */}
            </Link>
          </div>
          <small className='website-rights'>OneDose© 2024</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;