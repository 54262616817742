import { Outlet, useNavigate } from "react-router-dom";
import React, {useEffect, useState } from 'react'
import axios from "axios";
import { useDispatch,useSelector } from "react-redux";
import { addToken } from "./components/feature/TokenSlice";
import { useLocation } from "react-router-dom";
import { GiTruce } from "react-icons/gi";
import Loader from "./components/Loader/Loader";

const ProtectedRoute = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector((state)=>state.tokens);
    const PORT = process.env.REACT_APP_PORT
    axios.defaults.withCredentials=true;
    const [loading,setLoading] = useState(true);
    const BASE_URL = `http://${process.env.REACT_APP_URL}:${PORT}/`;
    
    // We validate the token first and if token is invalidated redirect user to the login page.
    useEffect(()=>{
        const checkAuth = async () => {
            try {
              const response = await axios.get(BASE_URL);
              // console.log(users['tokens'].length);
              if(response.data.authStatus && users['tokens'].length>1) console.log('authenticated');
              else if (response.data.authStatus) {
                const { token, username } = response.data;
                dispatch(addToken({ token, username }));
                console.log('setting token');
                console.log(users);
              } else {
                navigate('/login');
              }
            } catch (error) {
              navigate('/');
              console.error('Error checking authentication:', error);
            } finally{
              setLoading(false);
            }
          };
          checkAuth();
    },[location])

    if(loading){
      return <Loader/>
    }
  return (
    <Outlet/>
  )
}

export default ProtectedRoute