import {configureStore} from '@reduxjs/toolkit'
import TokenSliceReducer from '../feature/TokenSlice'
import DetailSliceReducer from '../feature/DetailSlice'
import PrescriptionImageSliceReducer from '../feature/PrescriptionImageSlice'

export const store = configureStore({
    reducer:{
        tokens:TokenSliceReducer,
        Details:DetailSliceReducer,
        PrescriptionImage:PrescriptionImageSliceReducer,
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})