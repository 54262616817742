import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { useDispatch, useSelector } from 'react-redux';  
import { removeToken } from '../feature/TokenSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoMdLogOut } from 'react-icons/io';
import { PiUserCircleFill } from 'react-icons/pi';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [username, setUsername] = useState('GUEST');
  const navigate = useNavigate();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const PORT = process.env.REACT_APP_PORT;
  
  // -------------------------------------AUTHENTICATION_AUTHORIZATION-----------------------------------------------------
  const info = useSelector((state) => state.tokens);
  const tokens = info['tokens'];
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      setUsername(tokens[1]['username']);
    } catch (error) {
      setUsername('GUEST');
    }
  }, [tokens.length]);

  // ----------------------------------------------------BUTTON STATE'S--------------------------------------------------------------------------------------------------------
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  
  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
    return () => window.removeEventListener('resize', showButton);
  }, []);

  // ------------------------------------------------------------------------------------------------------------------------------------
  const handleLogout = () => {
    axios
      .get(`http://${process.env.REACT_APP_URL}:${PORT}/logout`)
      .then(res => {
        console.log(res.data);
        dispatch(removeToken(username));
        navigate('/login');
      })
      .catch(err => console.error(err));
  };
  
  const handleLogin = () => {
    window.open('https://app.onedose.ai/auth', '_blank');
  };

  const handleContact = () => {
    navigate('/contact');
  };

  const routelocal = (url) => {
    window.location.replace(url);
  };

  //--------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar'>
          <div className='navbar-container container'>
            <NavLink to='/' className='navbar-logo' onClick={closeMobileMenu}>
              <img src='/favicon.ico' width={110} height={60} style={{ borderRadius: '.5rem', marginRight: '.5rem' }} />
            </NavLink>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {
              }
              {username !== 'GUEST' && (
                <>
                  <li className='nav-item'>
                    <NavLink to='/orders' className='nav-links' onClick={closeMobileMenu}>
                      Orders
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to='/products' className='nav-links' onClick={closeMobileMenu}>
                      Products
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to='/matrix' className='nav-links' onClick={closeMobileMenu}>
                      Matrix
                    </NavLink>
                  </li>
                </>
              )}
              
              {!button && (
                <>
                <span style={{ height: 155}} />
                <li className='nav-item spacer'>
                  <span className='nav-links' onClick={() => navigate("/")}>Home</span>
                </li>
                <li className='nav-item spacer'>
                  <span className='nav-links' onClick={() => navigate("/contact")}>Contact</span>
                </li>
                <li className='nav-item spacer'>
                  {username === 'GUEST' ? (
                    <span className='nav-links' onClick={handleLogin}>
                      Login
                    </span>
                  ) : (
                    <span className='nav-links' onClick={handleLogout}>
                      Logout
                    </span>
                  )}
                </li>
                </>
              )}
            </ul>
            {button && (
              <div className='navbar-user'>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                  <div className='btn-link'>
                    <PiUserCircleFill className='navbar-icon' size={40} style={{ margin: '5px', color: 'white' }} />
                    <div className='profile-menu'>
                      {username === 'GUEST' ? (
                        <ul>
                          <li className='nav-item'>
                            <span className='nav-links' onClick={() => navigate("/")}>Home</span>
                          </li>
                          <li className='nav-item'>
                            <span className='nav-links' onClick={() => navigate("/contact")}>Contact</span>
                          </li>
                          <li onClick={handleLogin}>
                            <IoMdLogOut size={30} />
                            <span className='nav-links'>Login</span>
                          </li>
                        </ul>
                      ) : (
                        <li onClick={handleLogout}>
                          <IoMdLogOut size={30} />
                          <span className='nav-links'>Logout</span>
                        </li>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
