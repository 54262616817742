import { createSlice} from "@reduxjs/toolkit";

export const DetailSlice = createSlice({
    name:'Details',
    initialState:{
        orderDetails:null,
    },
    reducers:{
        addDetails:(state,action) => {
            state.orderDetails=action.payload.orderDetails;
        },
        removeDetails:(state,action)=>{
            state.orderDetails=action.payload.orderDetails;
        }
    }
})

export const {addDetails,removeDetails} = DetailSlice.actions
export default DetailSlice.reducer