import './App.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Layout from './Layout.js';
import ProtectedRoute from './ProtectedRoute.js';
import Loader from './components/Loader/Loader.js';
import Greeting from './Screens/Greeting/Greeting.js';

// Lazy-loaded components
const Home = lazy(()=>import('./Screens/Home/Home.js'))
const Products = lazy(() => import('./Screens/Products/Product.js'));
const Orders = lazy(() => import('./Screens/Orders/Orders.js'));
const Matrix = lazy(() => import('./Screens/Matrix/Matrix.js'));
const Login = lazy(() => import('./Screens/Login/Login.js'));
const Contact = lazy(()=>import('./Screens/Contact Us/Contact.js'));
const Impact = lazy(()=>import('./Screens/Impact/Impact.js'));
const Calendar = lazy(()=>import('./Screens/Calendar/Calendar.js'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/orders' element={<Orders />} />
      <Route path='/matrix' element={<Matrix />} />
      <Route path='/products' element={<Products />} />
    </>
  )
);

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback={<Loader/>}>
          <Routes>
              
            <Route path="/" element={<Layout />}>
              <Route path='/greeting' element={<Greeting/>} />
              <Route path='/login' element={<Login />} />
              <Route path='/' element={<Home/>} />
              <Route path='/contact' element={<Contact/>} />
              <Route path='/calendar' element={<Calendar/>} />
              <Route path='/impact' element={<Impact/>} />
              <Route element={<ProtectedRoute />}>
                <Route path='/orders' element={<Orders />} />
                <Route path='/matrix' element={<Matrix />} />
                <Route path='/products' element={<Products />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
