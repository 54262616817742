import { configureStore, createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
    tokens:[{id:0,token:'initialToken',username:'USER',authStatus:false}]
}

export const TokenSlice = createSlice({
    name:'Token',
    initialState,
    reducers:{
        addToken:(state,action) => {
            const token = {id:nanoid,token:action.payload.token,username:action.payload.username,authStatus:true}
            state.tokens.push(token)
        },
        removeToken:(state,action)=>{
            state.tokens = state.tokens.filter((token)=> token.username!=action.payload)
        },
    }
})

export const {addToken,removeToken} = TokenSlice.actions
export default TokenSlice.reducer