import React, { useEffect } from 'react'
import './Greeting.css';
import { useNavigate } from 'react-router-dom';

const Greeting = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    const progress = document.querySelector('.progress');
        progress.addEventListener('animationend', () => {
            navigate('/');
        });
  },[])
  return (
    <div className='greeting-body'>
    <div class="greeting-container">
        <div class="message-box">
            <h1>Thank You!</h1>
            <p>Thank you for contacting us. We will get back to you shortly.</p>
            <div class="progress-bar">
                <div class="progress"></div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Greeting