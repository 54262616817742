import { createSlice } from "@reduxjs/toolkit";

export const PrescriptionImageSlice = createSlice({
    name:'PrescriptionImage',
    initialState:{
        showImage:false,
        image_url:null,
    },
    reducers:{
        setImage:(state,action)=>{
            state.image_url=action.payload.image_url;
            state.showImage=action.payload.showImage;
        }
    }
})

export const {setImage} = PrescriptionImageSlice.actions;
export default PrescriptionImageSlice.reducer