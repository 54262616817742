// import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import './loader.css'

function Loader() {
  return (
    <>
    <div className="loader-screen">
    <svg className="ip" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stopColor="#54b3a6" />
          <stop offset="33%" stopColor="#368893" />
          <stop offset="67%" stopColor="#2a6083" />
          <stop offset="100%" stopColor="#54b3a6" />
        </linearGradient>
        <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
          <stop offset="0%" stopColor="#54b3a6" />
          <stop offset="33%" stopColor="#368893" />
          <stop offset="67%" stopColor="#2a6083" />
          <stop offset="100%" stopColor="#54b3a6" />
        </linearGradient>
      </defs>
      <g fill="none" strokeLinecap="round" strokeWidth="16">
        <g className="ip__track" stroke="#ddd">
          <path d="M8,50s0-43,45-43,45,85,90,85,45-43,45-43"/>
          <path d="M190,50s0-43-45-43-45,85-90,85-45-43-45-43"/>
        </g>
        <g strokeDasharray="180 656">
          <path className="ip__worm1" stroke="url(#grad1)" strokeDashoffset="0" d="M8,50s0-43,45-43,45,85,90,85,45-43,45-43"/>
          <path className="ip__worm2" stroke="url(#grad2)" strokeDashoffset="358" d="M190,50s0-43-45-43-45,85-90,85-45-43-45-43"/>
        </g>
      </g>
    </svg>
    </div>
     
    </>
  );
}
export default Loader;